import { Resource } from '@opentelemetry/resources';
import { ATTR_SERVICE_NAME, ATTR_SERVICE_VERSION } from '@opentelemetry/semantic-conventions';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { ZoneContextManager } from '@opentelemetry/context-zone';

import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';

import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { Span as ApiSpan, trace, context, propagation } from '@opentelemetry/api';

// const COLLECTOR_URL = 'http://localhost:16686'; // Replace with our OTLP collector URL

/**
 * Injects the OpenTelemetry context into HTTP headers.
 *
 * @param {Span} span - The span to set in the context.
 * @param {Record<string, string>} headers - The headers object to inject the context into.
 *
 * @example
 * const span = startNewSpan('example-span');
 * injectContextIntoHeaders(span, headers);
 *
 * @see {@link @yonomi/util-otel#injectContextIntoHeaders}
 */
export const injectContextIntoHeaders = (span: ApiSpan, headers: Record<string, string>): void => {
  context.with(trace.setSpan(context.active(), span), () => {
    propagation.inject(context.active(), headers);
  });
};

/**
 * Initialize OpenTelemetry web tracing.
 */
export const initWebTracing = () => {
  const serviceName = 'platform-services-dashboard';

  // Define the resource with service name and version
  const resource = Resource.default().merge(
    new Resource({
      [ATTR_SERVICE_NAME]: serviceName,
      [ATTR_SERVICE_VERSION]: '0.0.1',
    })
  );

  // Create a WebTracerProvider with the specified resource
  const provider = new WebTracerProvider({
    resource: resource,
  });

  // For local development, uncomment the code below to use ConsoleSpanExporter and view traces in the console (Comment/uncomment as needed)
  // const consoleSpanExporter = new ConsoleSpanExporter();
  // const simpleSpanProcessor = new SimpleSpanProcessor(consoleSpanExporter);
  // provider.addSpanProcessor(simpleSpanProcessor);

  // TODO: Once we have a collector service running, we can use the OTLPTraceExporter to send traces to the collector (Comment/uncomment as needed)
  // Configure the OTLPTraceExporter to send traces to the specified collector URL (See ticket #959464)
  // const exporter = new OTLPTraceExporter({
  //   url: COLLECTOR_URL,
  // });

  // Use BatchSpanProcessor to batch spans before sending them to the exporter
  // const processor = new BatchSpanProcessor(exporter);
  // provider.addSpanProcessor(processor);

  // Register the provider with a ZoneContextManager and CompositePropagator
  provider.register({
    contextManager: new ZoneContextManager(), // Manages context in asynchronous operations
    propagator: new W3CTraceContextPropagator(), // Propagates trace context (trace ID, span ID) across service boundaries,
  });

  // Register instrumentations to automatically capture telemetry data
  registerInstrumentations({
    tracerProvider: provider,
    instrumentations: [
      new FetchInstrumentation({
        propagateTraceHeaderCorsUrls: /.*/,
        ignoreUrls: [/cdn\.cookielaw\.org/],
        clearTimingResources: true,
        applyCustomAttributesOnSpan: (span, request, result) => {
          span.setAttribute('app.synthetic_request', 'false');

          // We can add more custom attributes here if needed
          if (request instanceof Request) {
            span.setAttribute('http.url', request.url);
            span.setAttribute('http.method', request.method);
          }

          if (result instanceof Response) {
            span.setAttribute('http.status_code', result.status);
          }
        },
      }),
    ],
  });

  return provider.getTracer(serviceName);
};
