import { GlobalStyles, useTheme } from '@mui/material';

const PolicyStyling = ({ styles = {} }) => {
  const theme = useTheme();

  const defaultStyles = {
    h1: {
      fontSize: '36px',
      fontWeight: 600,
      lineHeight: '40px',
      letterSpacing: '-1px',
    },
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    'a:visited': {
      color: theme.palette.primary.main,
    },
    'a:hover': {
      textDecoration: 'underline',
    },
  };
  const mergedStyles = {
    ...defaultStyles,
    ...styles,
  };

  return <GlobalStyles styles={mergedStyles} />;
};

export default PolicyStyling;
