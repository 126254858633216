import { useEffect } from 'react';
import { Box } from '@mui/material';
import { MainContainer } from '../common/Theme';
import PolicyStyling from './PolicyStyling';

const CookiesPolicy = () => {
  useEffect(() => {
    document.title = 'Cookies Policy';
    const oneTrustScript = document.createElement('script');
    oneTrustScript.src = 'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
    oneTrustScript.type = 'text/javascript';
    oneTrustScript.charset = 'UTF-8';
    oneTrustScript.id = 'otprivacy-notice-script';
    oneTrustScript.setAttribute('data-testid', 'otnotice-script');
    oneTrustScript.setAttribute(
      'settings',
      'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vYWxsZWdpb24tcHJpdmFjeS5teS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9'
    );

    document.body.appendChild(oneTrustScript);

    oneTrustScript.onload = () => {
      const otContentScript = document.createElement('script');
      otContentScript.type = 'text/javascript';
      otContentScript.charset = 'UTF-8';
      otContentScript.setAttribute('data-testid', 'cookie-content-script');
      otContentScript.innerHTML = `
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(['https://privacyportal-cdn.onetrust.com/8b164882-ffc8-4155-8514-dc33976fd253/privacy-notices/721cd05b-75cd-49ba-94db-f8ea6410c9fe.json']);
        });
      `;

      document.body.appendChild(otContentScript);
    };

    return () => {
      document.body.removeChild(oneTrustScript);
    };
  }, []);

  return (
    <MainContainer>
      <Box sx={{ padding: 2 }}>
        <PolicyStyling />
        <Box id="otnotice-721cd05b-75cd-49ba-94db-f8ea6410c9fe" className="otnotice" sx={{ marginBottom: 2 }} />
      </Box>
    </MainContainer>
  );
};

export default CookiesPolicy;
