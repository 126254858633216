import React from 'react';
import { Box, Link, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';

const Footer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = theme.width?.drawerWidth || '256px';

  return (
    <Box
      component="footer"
      sx={{
        width: { xs: '100%', sm: `calc(100% - ${drawerWidth})` },
        minHeight: '64px',
        backgroundColor: theme.palette.grey[900],
        borderTop: '1px solid rgba(255, 255, 255, 0.12)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '24px 32px',
        marginTop: 'auto',
        zIndex: theme.zIndex.appBar - 1,
        marginLeft: { xs: 0, sm: drawerWidth },
        boxSizing: 'border-box',
      }}
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={'24px'}
        alignItems={isMobile ? 'flex-end' : 'center'}
        width="100%"
        justifyContent="flex-end"
      >
        <Link href="/privacy-policy" color="primary.light" underline="hover" variant="caption">
          Privacy Statement
        </Link>
        <Link href="/cookies-policy" color="primary.light" underline="hover" variant="caption">
          Cookies Policy
        </Link>
        <Link href="/terms-of-use" color="primary.light" underline="hover" variant="caption">
          Terms of Use
        </Link>
        <Typography variant="caption" color="text.secondary">
          © 2025 Schlage Lock Company LLC
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
