import { Paper, styled } from '@mui/material';

const StyledContent = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  font-family: ${theme.typography.fontFamily};
  line-height: 1.6;

  p {
    margin: 1em 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.4em;
    color: ${theme.palette.text.primary};
  }

  blockquote {
    margin: 1em 0 1em 1.7em;
    padding-left: 1em;
    border-left: 2px solid ${theme.palette.grey[600]};
    color: ${theme.palette.grey[400]};
  }

  /* Table Styles */
  table {
    margin: 1em 0;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  td, th {
    padding: 0.5em;
    border-color: ${theme.palette.grey[700]};
    vertical-align: top;
    word-wrap: break-word;
  }

  /* Specific column widths for your 2-column table */
  col:first-child {
    width: 45%;
  }

  col:last-child {
    width: 55%;
  }

  /* Legal document list styling */
  /* Primary list - 1., 2., 3. */
  ol {
    list-style-type: decimal;
    padding-left: 2em;
    counter-reset: section;
  }

  /* Increment section counter for each top-level list item */
  ol > li {
    counter-increment: section;
  }

  /* Second level - 1.1., 1.2., 2.1., etc */
  ol > li > ol {
    list-style-type: none;
    counter-reset: subsection;
  }

  ol > li > ol > li {
    position: relative;
    counter-increment: subsection;
    padding-left: 0.5em; /* Add left padding to the list item content */
  }

  ol > li > ol > li::before {
    content: counter(section) "." counter(subsection) ".";
    position: absolute;
    left: -2.5em;
    width: 2.5em;
    text-align: right;
  }

  /* Additional styling for the first paragraph in a list item to create space */
  ol > li > ol > li > p:first-child {
    margin-left: 0.5em; /* Add left margin to the first paragraph */
  }

  /* Third level - (a), (b), (c) */
  ol > li > ol > li > ol {
    list-style-type: none;
    counter-reset: subsubsection;
  }

  ol > li > ol > li > ol > li {
    position: relative;
    counter-increment: subsubsection;
  }

  ol > li > ol > li > ol > li::before {
    content: "(" counter(subsubsection, lower-alpha) ")";
    position: absolute;
    left: -2em;
    width: 2em;
  }

  .smallcaps {
    font-variant: small-caps;
    color: ${theme.palette.text.primary};
  }

  a {
    color: ${theme.palette.primary.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`
);

const tosContent = `
  <p><strong><span class="smallcaps">Yonomi Platform Agreement
        Terms</span></strong></p>
  <p>These Yonomi Platform Agreement Terms, including any schedules and
    any mutually executed purchase order, statement of work, quote or order
    form (each, an <span dir="rtl">“</span><strong>Order Form</strong>”),
    each of which are incorporated by reference into this Agreement, govern
    Customer<span dir="rtl">’</span>s use of the Offerings. This Agreement
    is effective as of the date identified on an Order Form (<span dir="rtl">“</span><strong>Effective Date</strong>”).
    The customer
    identified in the applicable Order Form (<span dir="rtl">“</span><strong>Customer</strong>”) and Yonomi, LLC. (<span
      dir="rtl">“</span><strong>Yonomi</strong>”) hereby agree as follows:</p>

  <ol>
    <li>
      <p><strong><span class="smallcaps">Definitions.</span></strong> Any
        capitalized terms in this Agreement will have the meanings attributed to
        them below:</p>
      <blockquote>
        <p><span dir="rtl">“</span><strong>Access Protocols</strong>” means the
          passwords, access codes, technical specifications, connectivity
          standards or protocols, or other relevant procedures, as may be
          necessary to allow Customer or a Customer Product to access a
          Service.</p>
        <p><span dir="rtl">“</span><strong>Customer End User Data</strong>”
          means End User Data that originates from a Customer Product or is
          provided to Yonomi for a Service.</p>
        <p><span dir="rtl">“</span><strong>Customer Product</strong>” means a
          Product that is distributed by or on behalf of, or primarily bears the
          trademarks owned by, Customer, and is identified in an Order Form.</p>
        <p><span dir="rtl">“</span><strong>Documentation</strong>” means the
          user manuals and technical materials, provided by Yonomi to Customer in
          connection with Customer<span dir="rtl">’</span>s use of the Offerings,
          that describe the features, functionality or operation of the applicable
          Offering.</p>
        <p><span dir="rtl">“</span><strong>End User</strong>” means any end user
          of a Product.</p>
        <p><span dir="rtl">“</span><strong>End User Data</strong>” means any
          data or content that is collected from or relates to an End User or a
          Product.</p>
        <p><span dir="rtl">“</span><strong>Error</strong>” means a reproducible
          failure of the Service or Software to substantially conform to the
          Documentation.</p>
        <p><span dir="rtl">“</span><strong>Evaluation Term</strong>” means a
          definite period of time (if any), specifically identified on an Order
          Form, in which Customer may use and access the Offerings without
          incurring Fees.</p>
        <p><span dir="rtl">“</span><strong>Offering</strong>” means a Yonomi
          offering identified on the applicable Order Form that Customer agrees to
          license pursuant to this Agreement, which may include Software, a
          Service, professional services, maintenance and support, and other
          offerings specified in the applicable Order Form.</p>
        <p><span dir="rtl">“</span><strong>Product</strong>” means any product
          or device (e.g., a <span dir="rtl">“</span>smart home” device or
          application) that is managed by, sends data to (directly or indirectly
          (e.g., through Customer systems)), or is provisioned to access, a
          Service, including any end-user device or application that manages,
          controls, or interfaces with, such product or device.</p>
        <p><span dir="rtl">“</span><strong>Service</strong>” or <span dir="rtl">“</span><strong>Yonomi Platform
            Service</strong>” means the
          applicable service for Product and End User management delivered by
          Yonomi, as further described in an applicable Order Form.</p>
        <p><span dir="rtl">“</span><strong>Software</strong>” means any
          software, software development kits (SDKs), or application programming
          interfaces (APIs) and any related materials (including installation
          tools, sample code, source code, software libraries and documentation),
          described in the Order Form, described in the Documentation, or provided
          to Customer to enable Products or Customer systems to interface with or
          access the Service, including any modifications, implementations,
          improvements, extensions, or other updates thereto.</p>
        <p><strong><span dir="rtl">“</span>Term”</strong> means the term for the
          subscription to a Service as set forth in an applicable Order Form.</p>
      </blockquote>
    </li>
    <li>
      <p><strong><span class="smallcaps">License Grant; Access
            rights</span></strong></p>
      <ol>
        <li>
          <p><strong>License Grant</strong>. Subject to the terms and conditions
            of this Agreement (including Customer<span dir="rtl">’</span>s
            obligation to pay the Fees), Yonomi hereby grants to Customer, during
            the Term, a non-sublicenseable, non-transferable, non-exclusive license
            to (a) create derivative works of the Software (solely to the extent
            necessary to implement the Software on the Customer Product(s) specified
            in the Order Form, and (b) reproduce the Software to install the
            Software on such Customer Products, and (c) distribute the Software in
            or to such Customer Products, subject to an end user license agreement
            that is at least as protective of the Software and Service as this
            Agreement.</p>
        </li>
        <li>
          <p><strong>Open Source Software</strong>. Certain items of independent,
            third-party code may be included in the Software that are subject to
            open source licenses (<span dir="rtl">“</span><strong>Open Source
              Software</strong>”). Nothing in this Agreement limits Customer<span dir="rtl">’</span>s rights under, or
            grants
            Customer rights that
            supersede, the terms and conditions of any applicable end user license
            for such Open Source Software.</p>
        </li>
        <li>
          <p><strong>Documentation License</strong>. Subject to the terms and
            conditions of this Agreement, Yonomi hereby grants to Customer a
            non-exclusive, non-transferable, non-sublicenseable license during the
            Term to make copies of the Documentation provided by Yonomi, solely for
            use by Customer in connection with the exercise of rights granted in
            section 2.1.</p>
        </li>
        <li>
          <p><strong>Yonomi Platform Service</strong><span class="smallcaps">.</span></p>
          <p><strong>Subscription to the Service.</strong> Subject to the terms
            and conditions of this Agreement (including Customer<span dir="rtl">’</span>s obligation to pay the Fees),
            Yonomi
            will provide
            Customer and the Customer Products in the territory specified in the
            applicable Order Form (which, if not specified in the Order Form, the
            territory is worldwide), during the Term, with non-sublicensable,
            non-transferable, non-exclusive access to and use of the applicable
            Service, through the Software.</p>
          <p><strong>Access.</strong> On or as soon as commercially practicable
            after the date the Order Form is last executed, Yonomi will provide to
            Customer the setup materials necessary to establish passwords, security
            protocols and policies and network links or connections and Access
            Protocols to allow Customer to access the applicable Service (and to
            configure the Customer Products to access the applicable Service) in
            accordance with the Access Protocols. Customer will use commercially
            reasonable efforts to prevent unauthorized access to, or use of, the
            Service, and notify Yonomi promptly of any such unauthorized use known
            to Customer.</p>
        </li>
        <li>
          <p><strong>Restrictions</strong></p>
          <ol type="a">
            <li>
              <p><strong>Software License Restrictions</strong>. Customer
                acknowledges that the Software and its structure, organization and
                source code constitute valuable trade secrets of Yonomi and its
                suppliers. Accordingly, except as expressly permitted in this Agreement,
                Customer agrees not to (1) authorize or permit use of the Software or
                Documentation by persons other than its employees; (2) market or
                distribute the Software or the Documentation; (3) assign, sublicense,
                sell, lease or otherwise transfer or convey, or pledge as security or
                otherwise encumber, Customer<span dir="rtl">’</span>s rights under the
                licenses granted in section 2; (4) use the Software to provide services
                or process data for the benefit of, or on behalf of, any third party,
                other than End Users; (5) modify the Software or Documentation, except
                with the prior written consent of Yonomi; (6) combine or integrate the
                Software with hardware, software or technology not provided to Customer
                by Yonomi hereunder, other than Customer Products; (7) otherwise use or
                copy the Software, except as expressly allowed under this Agreement; (8)
                remove, alter or obscure any proprietary notices of Yonomi or its
                suppliers; or (9) decompile, disassemble or reverse engineer the
                Software or otherwise attempt to obtain or perceive the source code from
                which any component of the Software is compiled or interpreted. Customer
                hereby acknowledges that nothing in this Agreement will be construed to
                grant Customer any right to obtain or use such source code.</p>
            </li>
            <li>
              <p><strong>Service Restrictions</strong>. Customer will not, and
                will not permit any other party to: (1) knowingly interfere with or
                disrupt the integrity or performance of the Service or the data
                contained therein; (2) harass or interfere with Yonomi or any other
                Yonomi customer<span dir="rtl">’</span>s use and enjoyment of the
                Service; (3) reverse engineer, disassemble or decompile any component of
                the Service; (4) interfere in any manner with the operation of the
                Service or the hardware and network used to operate the Service; (5)
                sublicense any of Customer<span dir="rtl">’</span>s rights under this
                Agreement, or otherwise use the Service for the benefit of a third
                party, other than End Users; (6) modify, copy or make derivative works
                based on any part of the Service; or (7) otherwise use the Service in
                any manner that exceeds the scope of use expressly permitted under this
                Agreement. This section will survive expiration or termination of this
                Agreement for any reason.</p>
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">Fees and
            Payment</span></strong></p>
      <ol>
        <li>
          <p><strong>Fees</strong>. The fees for each of the Offerings will be
            described in each Order Form (<span dir="rtl">“</span><strong>Fees</strong>”) and may include Fees that are
            invoiced a single time as a fixed Fee, prior to the beginning of each
            month (for, e.g., license Fees, subscription Fees, third-party monthly
            subscription fees, or support Fees), and at the end of each month (for,
            e.g., hourly professional services, third-party subscription usage fees,
            or usage Fees).</p>
        </li>
        <li>
          <p><strong>Payments</strong>. Customer will pay the Fees described
            in each valid invoice issued by Yonomi with respect to that Order Form.
            All month-based Fees apply to any full or partial month. All payments
            must be made in U.S. dollars. Any payment due or portion thereof not
            received by Yonomi within thirty (30) days after the date of invoice
            will bear an additional charge of one and one-half percent (1.5%) per
            month (including any partial month) from the date due until actually
            received.</p>
        </li>
        <li>
          <p><strong>Delivery</strong>. As soon as commercially practicable
            after the date the applicable Order Form is last executed, Yonomi will
            deliver to Customer one (1) copy of each of the Software (including API
            specifications and API tokens) and the Documentation for use by Customer
            in exercising its rights under the licenses granted in this Agreement.
            Delivery will be deemed complete upon receipt by Customer of electronic
            files in which the Software and Documentation are digitally stored.
            Unless otherwise agreed between the parties in a written agreement
            (e.g., if the Order Form provides for configuration services), Yonomi
            will have no obligation to install or configure the Software for or on
            behalf of Customer.</p>
        </li>
        <li>
          <p><strong>Taxes</strong>. All payments under this Agreement are
            exclusive of taxes and duties, and Customer agrees to bear and be
            responsible for the payment of all taxes and duties including, but not
            limited to, all sales, use, rental, receipt, personal property and other
            taxes (but excluding taxes based upon Yonomi<span dir="rtl">’</span>s
            income), which may be levied or assessed in connection with this
            Agreement. Customer will make all payments of the Fees to Yonomi free
            and clear of, and without reduction for, any withholding taxes; any such
            taxes imposed on payments of the Fees to Yonomi will be Customer<span dir="rtl">’</span>s sole
            responsibility, and Customer will provide
            Yonomi with official receipts issued by the appropriate taxing
            authority, or such other evidence as Yonomi may reasonably request, to
            establish that such taxes have been paid.</p>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">Customer
            obligations</span></strong></p>
      <ol>
        <li>
          <p><strong>Professional Services</strong>. If so specified in an
            Order Form, the Customer has engaged Yonomi to perform certain
            professional services identified therein, and this section will apply to
            the same. Subject to the terms and conditions set forth in this
            Agreement, Yonomi will perform the services as set forth in the
            applicable Order Forms separately executed by the parties (the <span dir="rtl">“</span><strong>Professional
              Services</strong>”). Provider
            will perform the Professional Services in a professional manner in
            accordance with industry standards. Customer<span dir="rtl">’</span>s
            sole and exclusive remedy for Yonomi<span dir="rtl">’</span>s breach of
            the foregoing warranty is to: (a) have Yonomi reperform the Professional
            Services; or (b) receive a refund of the Fees paid for that portion of
            the Professional Services that fail to conform to the foregoing
            warranty.</p>
        </li>
        <li>
          <p><strong>Modifications</strong>. Customer may at any time request
            a modification to the Professional Services to be performed pursuant to
            an applicable Order Form by written request to Yonomi specifying the
            desired modifications. Yonomi will, within a reasonable time following
            receipt of such request, submit an estimate of the cost for such
            modifications and a revised estimate of the time for performance of the
            Professional Services pursuant to a newly prepared Order Form. If
            accepted in writing by Client, such modifications in the Order Form will
            be performed under the terms of this Agreement. Modifications in any
            Order Form will become effective only when a written change request is
            executed by authorized representatives of both parties.</p>
        </li>
        <li>
          <p><strong>Customer Assistance</strong><span class="smallcaps">.</span> Customer will make available in a
            timely
            manner at no charge to Yonomi all technical data, computer facilities,
            programs, files, documentation, test data, sample output, or other
            information and resources of Customer required by Yonomi for the
            performance of the Professional Services as specified in an applicable
            Order Form. Customer will be responsible for, and assumes the risk of,
            any problems resulting from, the content, accuracy, completeness and
            consistency of all such data, materials and information supplied by
            Customer.</p>
        </li>
        <li>
          <p><strong>Custom Development</strong>. To the extent an Order Form
            specifies that the Professional Services include custom development of
            software (or other technology) that is specific to Customer or Customer
            Products and not applicable to other customers or devices (<span dir="rtl">“</span><strong>Customer-Specific
              Code</strong>”), Yonomi
            hereby assigns its rights in the Customer-Specific Code to Customer, and
            Customer hereby grants Yonomi an irrevocable, perpetual, worldwide,
            fully paid-up, royalty-free, nonexclusive license to use, reproduce,
            create derivative works of, distribute, display, and perform the
            Customer-Specific Code.</p>
        </li>
        <li>
          <p><strong>Feature Acceleration</strong>. To the extent an Order
            Form specifies that the Professional Services include development of
            software (or other technology) that is not Customer-Specific Code, such
            software will be included in the term <span dir="rtl">“</span>Software”
            and licensed as Software hereunder.</p>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">Customer
            Products</span>.</strong> As between Yonomi and Customer, Customer is
        solely responsible for supporting and maintaining the Customer
        Product(s). Customer represents and warrants that the Customer
        Product(s) will not: (a) violate any laws or regulations (including any
        privacy laws) or any obligations or restrictions imposed by any third
        party; (b) be directed to or harmful to minors in any way; or (c)
        contain computer viruses, worms, or any software intended to damage or
        alter a computer system or data.</p>
    </li>
    <li>
      <p><strong><span class="smallcaps">Technical Support;
            Maintenance</span></strong></p>
      <ol>
        <li>
          <p><strong>Generally</strong>. Subject to Customer<span dir="rtl">’</span>s payment of the applicable
            subscription and support
            Fees, beginning upon delivery of the Software and/or Service and ending
            upon termination of the Agreement or the applicable Order Form (or
            earlier if so set forth in an applicable Order Form) (the <span dir="rtl">“</span><strong>Support
              Term</strong>”), Yonomi will provide
            Yonomi<span dir="rtl">’</span>s standard technical support as described
            in this section 6.</p>
        </li>
        <li>
          <p><strong>Error Corrections</strong>. Yonomi will use commercially
            reasonable efforts to correct all Errors in the Software and the Service
            reported by Customer over the phone or in writing to Yonomi.</p>
        </li>
        <li>
          <p><strong>Standard Support</strong>. If the Customer has paid the
            applicable Fees therefor, then, during the Support Term, Yonomi will
            provide an email address for use by Customer Monday through Friday, 9am
            – 6pm CT, US holidays excluded, for problem resolution
            assistance.</p>
        </li>
        <li>
          <p><strong>Premium Developer Support</strong>. If the Order Form
            specifies <span dir="rtl">“</span>Premium Developer Support” and
            Customer has paid the applicable Fees therefor, then, during the Support
            Term, Yonomi will provide an email address and Instant Message handle
            for use by Customer 24x7x365 and a dedicated technical support resource
            for problem resolution assistance.</p>
        </li>
        <li>
          <p><strong>Versions</strong>. Yonomi will provide the support set
            forth in this section, (a) for the then-current generally available
            release of the Software, and (b) for six (6) months after the
            introduction of a new generally available release (in Yonomi<span dir="rtl">’</span>s discretion), for the
            previous generally available
            release of the Software.</p>
        </li>
        <li>
          <p><strong>Maintenance</strong>. Subject to Customer<span dir="rtl">’</span>s payment of the Fees, beginning
            upon delivery of the
            Software and/or Service and for the period of the Support Term, Yonomi
            will provide maintenance updates for the then-current generally
            available release of the Software as determined by Yonomi in its sole
            discretion.</p>
        </li>
        <li>
          <p><strong>Maintenance Exclusions</strong>. Yonomi will have no
            responsibility or liability of any kind, whether for breach of warranty
            or otherwise, arising or resulting from: (a) use of any version of a
            Service or Software other than the then-current unmodified version
            provided to Customer; (b) any problems which are not Errors; (c)
            problems caused by failed Internet connections or other hardware,
            software or equipment which is not owned, controlled or operated by
            Yonomi; (d) nonconformities resulting from misuse, abuse, negligence, or
            improper or unauthorized use of all or any part of a Service or
            Documentation; (e) problems or Errors caused by Customer<span dir="rtl">’</span>s or other third party<span
              dir="rtl">’</span>s
            products, services or equipment; (f) Customer-Specific Code (except to
            the extent expressly provided in an Order Form), or (g) modification,
            amendment, revision, or change to a Service or Software by any party
            other than Yonomi.</p>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">Confidentiality</span></strong></p>
      <ol>
        <li>
          <p><strong>Confidential Information</strong>. During the term of
            this Agreement, each party (the <span dir="rtl">“</span><strong>Disclosing Party</strong>”) may provide the
            other party (the <span dir="rtl">“</span><strong>Receiving
              Party</strong>”) with certain information regarding the Disclosing
            Party<span dir="rtl">’</span>s business, technology, products, or
            services or other confidential or proprietary information (collectively,
            <span dir="rtl">“</span><strong>Confidential Information</strong>”). The
            Disclosing Party will mark all Confidential Information in tangible form
            as <span dir="rtl">“</span>confidential” or <span dir="rtl">“</span>proprietary” or with a similar legend,
            and identify
            all Confidential Information disclosed orally as confidential at the
            time of disclosure and provide a written summary of such Confidential
            Information within thirty (30) days after such oral disclosure.
            Regardless of whether so marked or identified, the Software, nonpublic
            information about the Service, the Documentation, all enhancements and
            improvements thereto, and the pricing and terms in this Agreement and
            all associated Order Forms will be considered Confidential Information
            of Yonomi.
          </p>
        </li>
        <li>
          <p><strong>Protection of Confidential Information</strong>. The
            Receiving Party agrees that it will not use or disclose to any third
            party any Confidential Information of the Disclosing Party, except as
            expressly permitted under this Agreement. The Receiving Party will
            authorize access to the Confidential Information only by those personnel
            who have a need to know, who have confidentiality obligations no less
            restrictive than those set forth herein, and who have been informed of
            the confidential nature of such information. Except with respect to End
            User Data (which is addressed in the Data Processing Schedule), the
            Receiving Party will protect the Disclosing Party<span dir="rtl">’</span>s Confidential Information from
            unauthorized use,
            access, or disclosure in the same manner that it protects its own
            proprietary information of a similar nature, but in no event with less
            than reasonable care. At the Disclosing Party<span dir="rtl">’</span>s
            request or upon termination of this Agreement, the Receiving Party will
            return to the Disclosing Party or destroy (or permanently erase in the
            case of electronic files) all copies of the Confidential Information
            that the Receiving Party does not have a continuing right to use under
            this Agreement, and the Receiving Party will provide to the Disclosing
            Party a written affidavit certifying compliance with this
            sentence.</p>
        </li>
        <li>
          <p><strong>Exceptions.</strong> The confidentiality obligations set
            forth in this section will not apply to any information that (a) becomes
            generally available to the public through no fault of the Receiving
            Party; (b) is lawfully provided to the Receiving Party by a third party
            free of any confidentiality duties or obligations; (c) was already known
            to the Receiving Party at the time of disclosure; or (d) the Receiving
            Party can prove, by clear and convincing evidence, was independently
            developed by employees and contractors of the Receiving Party who had no
            access to the Confidential Information. In addition, the Receiving Party
            may disclose Confidential Information or End User Data to the extent
            that such disclosure is necessary for the Receiving Party to enforce its
            rights under this Agreement or is required by law, regulation or by the
            order of a court or similar judicial or administrative body, provided
            that the Receiving Party promptly notifies the Disclosing Party in
            writing of such required disclosure and cooperates with the Disclosing
            Party if the Disclosing Party seeks an appropriate protective
            order.</p>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">Proprietary Rights; Trademark
            License</span></strong></p>
      <ol>
        <li>
          <p><strong>Ownership.</strong> Customer acknowledges that Yonomi
            retains all right, title and interest in and to each Service, Software,
            Documentation and all Yonomi Confidential Information and technology
            provided to Customer by Yonomi in connection with the Offerings (the
            <span dir="rtl">“</span><strong>Yonomi Technology</strong>”), and that
            the Yonomi Technology is protected by intellectual property rights owned
            by or licensed to Yonomi. Other than as expressly set forth in this
            Agreement, no license or other rights in the Yonomi Technology are
            granted to Customer. To the extent Customer develops code that (a)
            implements calls to the Software or Service (including through the
            provided APIs) and (b) is disclosed to Yonomi, Yonomi will have a
            perpetual and irrevocable license to use, reproduce, distribute, create
            derivative works of, display, and perform (i.e., <span dir="rtl">“</span>utilize”) the same or similar code.
            Customer hereby
            grants to Yonomi a royalty-free, worldwide, transferable, sublicensable,
            irrevocable, perpetual license to incorporate any and all feedback
            related to the Offerings into any Yonomi Technology and to utilize such
            feedback (as included in the Yonomi Technology). Yonomi will not
            publicly identify Customer as the source of any such feedback.
          </p>
        </li>
        <li>
          <p><strong>Customer Use</strong>. Customer will exercise its rights
            with respect to the Offerings solely to the extent that all the Fees for
            such Offerings are paid for under the applicable Order Form.</p>
        </li>
        <li>
          <p><strong>Trademark License</strong>. Subject to the terms and
            conditions of this Agreement, (a) Yonomi hereby grants to Customer a
            non-exclusive, non-transferable, revocable, royalty-free license to use
            the Yonomi trademarks provided to Customer under this Agreement
            (Yonomi<span dir="rtl">’</span>s <span dir="rtl">“</span><strong>Marks</strong>”) to market and promote the
            Customer Products and the Yonomi consumer services, and (b) Customer
            will include Yonomi-enabled compatibility information regarding any
            Products, and the Marks specified by Yonomi, in its marketing and
            packaging of the Customer Products, in a manner agreed by the parties
            but in no case less prominent that typical <span dir="rtl">“</span>ingredient” or <span
              dir="rtl">“</span>powered by”
            branding. Subject to the terms and conditions of this Agreement,
            Customer hereby grants to Yonomi a non-exclusive, non-transferable,
            revocable, royalty-free license to use the Customer trademarks provided
            to Yonomi under this Agreement (Customer<span dir="rtl">’</span>s <span
              dir="rtl">“</span><strong>Marks</strong>”) to identify Customer as a
            customer of Yonomi and to otherwise perform its obligations under this
            Agreement.</p>
        </li>
        <li>
          <p><strong>Trademark License; Restrictions</strong>. Each party (the
            <span dir="rtl">“</span><strong>Trademark Licensee</strong>”), when
            using the Marks of the other party (the <span dir="rtl">“</span><strong>Trademark Licensor</strong>”), will
            comply
            with the Trademark Licensor<span dir="rtl">’</span>s trademark usage
            guidelines in effect from time to time. The Trademark Licensor may
            terminate the foregoing trademark license if, in its reasonable
            discretion, the Trademark Licensee<span dir="rtl">’</span>s use of the
            Trademark Licensor<span dir="rtl">’</span>s Marks tarnishes, blurs, or
            dilutes the quality associated with the Trademark Licensor<span dir="rtl">’</span>s Marks or the associated
            goodwill, and such problem
            is not cured within thirty (30) days after receipt of notice. The
            Trademark Licensee acknowledges Trademark Licensor<span dir="rtl">’</span>s ownership and exclusive rights
            in and to the
            Trademark Licensor<span dir="rtl">’</span>s Marks, and the Trademark
            Licensee<span dir="rtl">’</span>s use of the Trademark Licensor<span dir="rtl">’</span>s Marks will accrue
            to the benefit of the Trademark
            Licensor. The Trademark Licensee will not attempt to register the
            Trademark Licensor<span dir="rtl">’</span>s Marks as its own in any
            jurisdiction, or adopt, use, or attempt to register any trademark that
            is confusingly similar to the Trademark Licensor<span dir="rtl">’</span>s Marks in any jurisdiction.
            Customer will obtain
            Yonomi<span dir="rtl">’</span>s prior written approval of each type of
            use of Yonomi<span dir="rtl">’</span>s Marks, which approval will not be
            unreasonably withheld.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">Warranties;
            Disclaimers</span></strong></p>
      <ol>
        <li>
          <p><strong>Mutual Warranties.</strong> Each party hereto represents
            and warrants that (a) it is duly organized, validly existing and in good
            standing under the laws of its jurisdiction of incorporation; and (b)
            that this Agreement, when executed and delivered, will constitute a
            valid and binding obligation of such party and will be enforceable
            against such party in accordance with its terms.</p>
        </li>
        <li>
          <p><strong>Disclaimers</strong><span class="smallcaps">.</span>
            YONOMI MAKES NO (AND HEREBY DISCLAIMS ALL) WARRANTIES, REPRESENTATIONS,
            OR CONDITIONS, WHETHER WRITTEN, ORAL, EXPRESS, IMPLIED OR STATUTORY,
            INCLUDING, WITHOUT LIMITATION, WITH RESPECT TO SATISFACTORY QUALITY,
            SECURITY, ACCURACY, COURSE OF DEALING, TRADE USAGE OR PRACTICE,
            MERCHANTABILITY, TITLE, NONINFRINGEMENT, OR FITNESS FOR A PARTICULAR
            PURPOSE, WITH RESPECT TO THE USE, MISUSE, OR INABILITY TO USE THE
            OFFERINGS (IN WHOLE OR IN PART).</p>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">Indemnity</span></strong></p>
      <ol>
        <li>
          <p><strong>By Yonomi.</strong> Yonomi will indemnify, defend and
            hold Customer harmless from any third-party suit and any damages,
            losses, costs, and expenses awarded therein, to the extent such suit is
            based on any claim that any Offering infringes, misappropriates or
            violates the intellectual property rights of such third party. If any
            portion of the Software or Service becomes, or in Yonomi<span dir="rtl">’</span>s opinion is likely to
            become, the subject of a claim
            of infringement, Yonomi may, at Yonomi<span dir="rtl">’</span>s option:
            (a) procure for Customer the right to continue using the Service or
            Software; (b) replace the Service or Software with non-infringing
            software or services which do not materially impair the functionality of
            the Service or Software; (c) modify the Service or Software so that it
            becomes non-infringing; or (d) terminate this Agreement with respect to
            such Software or Service and refund any unused fees paid by Customer to
            Yonomi with respect to the same for the remainder of the term then in
            effect, and upon such termination, Customer will immediately cease all
            use of the same. Notwithstanding the foregoing, Yonomi will have no
            obligation under this section or otherwise with respect to any
            infringement claim based upon (w) any use of any Service or Software not
            in accordance with this Agreement or as specified in the Documentation;
            (x) any use of any Service or Software in combination with any other
            product, equipment, software or data not supplied by Yonomi (<span dir="rtl">“</span><strong>Other
              Product</strong>”), where such
            combination or Other Product is the basis for the infringement claim;
            (y) any modification of any Service or Software by any person other than
            Yonomi or its authorized agents, provided that the Service or Software
            would not be infringing had the Service or Software, as applicable, not
            been modified by such third person; or (z) any Customer-Specific Code
            (each an <span dir="rtl">“</span><strong>Excluded Claim</strong>”). This
            subsection states the sole and exclusive remedy of Customer and the
            entire liability of Yonomi, or any of the officers, directors,
            employees, shareholders, contractors or representatives of the
            foregoing, for infringement claims, actions, and damages.</p>
        </li>
        <li>
          <p><strong>By Customer.</strong> Customer will indemnify, defend and
            hold Yonomi harmless from any third-party suit and any damages, losses,
            costs, and expenses awarded therein, to the extent such suit is based on
            any Excluded Claim or any Customer End User Data (other than Yonomi<span dir="rtl">’</span>s breach of its
            obligations with respect to Customer
            End User Data under this Agreement).</p>
        </li>
        <li>
          <p><strong>Procedure.</strong> The indemnifying party<span dir="rtl">’</span>s obligations as set forth above
            are expressly
            conditioned upon each of the foregoing: (a) the indemnified party will
            promptly notify the indemnifying party in writing of any threatened or
            actual claim or suit; (b) the indemnifying party will have sole control
            of the defense or settlement of any claim or suit (provided that it will
            not settle such claim without the indemnified party<span dir="rtl">’</span>s approval (which will not be
            unreasonably withheld);
            and (c) the indemnified party will cooperate with the indemnifying party
            to facilitate the settlement or defense of any claim or suit.</p>
        </li>
      </ol>
    </li>
    <li>
      <p><span class="smallcaps"><strong>Limitation of Liability.</strong>
          EXCEPT FOR ANY BREACH OF CONFIDENTIALITY OBLIGATIONS, LICENSE
          LIMITATIONS OR RESTRICTIONS, OR INFRINGEMENT OF THE OTHER PARTY<span dir="rtl">’</span>S INTELLECTUAL PROPERTY
          RIGHTS, NEITHER PARTY WILL BE
          LIABLE FOR: (A) ANY LOSS OF PROFITS, LOSS OF BUSINESS, FINES OR
          PENALTIES, COSTS OF PROCUREMENT OF SUBSTITUTE SERVICES, SOFTWARE OR
          TECHNOLOGY, LOSS OF USE OR DATA, INTERRUPTION OF BUSINESS, OR FOR
          INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND,
          REGARDLESS OF THE LEGAL OR EQUITABLE THEORY ON THE BASIS OF WHICH ANY
          CLAIM FOR DAMAGES IS BROUGHT, INCLUDING, BUT NOT LIMITED TO, BREACH OF
          CONTRACT, TORT OR STATUTE, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES, OR (B) ANY AMOUNTS IN EXCESS OF THE
          AGGREGATE AMOUNT OF FEES PAID TO YONOMI BY CUSTOMER DURING THE TWELVE
          (12) MONTH PERIOD PRECEDING THE FIRST EVENT GIVING RISE TO LIABILITY
          UNDER THIS AGREEMENT.</span></p>
    </li>
    <li>
      <p><strong><span class="smallcaps">Term and
            Termination</span></strong></p>
      <ol>
        <li>
          <p><strong>Term and Renewal.</strong> This Agreement will commence
            on the Effective Date and remain in effect for a period of one (1) year.
            The term for the licenses, subscriptions, and other of the Offerings
            that involve a term and are purchased under this Agreement will commence
            on the date specified in the Order Form and will continue for the period
            specified in the Order Form (notwithstanding any expiration of the Term,
            but subject to termination). Unless earlier terminated as provided in
            this Agreement, this Agreement will automatically renew for consecutive
            one (1) year terms unless either party notifies the other party, at
            least thirty (30) days prior to the end of the then-current term, of its
            intent to not renew the term (the period during which this Agreement
            remains in effect, the <span dir="rtl">“</span><strong>Term</strong>”).</p>
        </li>
        <li>
          <p><strong>Termination.</strong> Either party may terminate this
            Agreement or applicable Order Form upon written notice (a) if the other
            party breaches any material provision of this Agreement and fails to
            cure such breach within thirty (30) days after written notice thereof;
            (b) immediately if the other party terminates or suspends its business,
            becomes subject to any bankruptcy or insolvency proceeding under federal
            or state or similar statute that is not dismissed within sixty (60)
            days, or becomes insolvent or subject to direct control by a trustee,
            receiver, or similar authority or (c) as described in the Data
            Processing Schedule.</p>
        </li>
        <li>
          <p><strong>Effects of Termination</strong>. Upon any termination or
            expiration of this Agreement or applicable Order Form, (a) Customer (i)
            will immediately discontinue all use of the Offerings and any
            Documentation, as well as any use of Yonomi<span dir="rtl">’</span>s
            Confidential Information; and (ii) will promptly pay to Yonomi all
            amounts due and remaining payable hereunder; (b) each party (i) will
            delete any of the other party<span dir="rtl">’</span>s Confidential
            Information from the party<span dir="rtl">’</span>s computer storage or
            any other media, including, but not limited to, online and off-line
            libraries; (ii) will return to the other party or, at the other
            party<span dir="rtl">’</span>s option, destroy, all copies of other
            party<span dir="rtl">’</span>s Confidential Information then in the
            party<span dir="rtl">’</span>s possession; and (c) Yonomi will enable
            Customer to access the Customer End User Data for thirty (30) days,
            after which period, Yonomi may delete all Customer End User
            Data.</p>
        </li>
        <li>
          <p><strong>Survival.</strong> The following sections, and any other
            provisions specified as irrevocable or surviving in this Agreement, will
            survive expiration or termination of this Agreement for any reason: 1
            (Definitions), 2.5 (Restrictions), 3 (Fees and Payment), 7
            (Confidentiality), 8.1 (Ownership), 9.2 (Disclaimers), 10 (Indemnity),
            11 (Limitation of Liability), 12 (Term and Termination), and 13
            (General).</p>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">General</span></strong></p>
      <ol>
        <li>
          <p><strong>Entire Agreement.</strong> This Agreement (including all
            attached or incorporated schedules or exhibits) constitutes the entire
            understanding of the parties with respect to the subject matter hereof,
            and supersedes all prior and contemporaneous written and oral agreements
            with respect to the subject matter. The headings of in this Agreement
            are for convenience and are not to be used in interpreting this
            Agreement. As used in this Agreement, the word <span dir="rtl">“</span>including” means <span
              dir="rtl">“</span>including but
            not limited to.”</p>
        </li>
        <li>
          <p><strong>Independent Contractors.</strong> The parties hereto are
            independent contractors. Nothing in this Agreement will be deemed to
            create an agency, employment, partnership, fiduciary, or joint venture
            relationship between the parties. Neither party is the representative of
            the other party for any purpose and neither party has the power or
            authority as agent, employee, or in any other capacity to represent, act
            for, bind, or otherwise create or assume any obligation on behalf of the
            other party for any purpose whatsoever.</p>
        </li>
        <li>
          <p><strong>Compliance with Laws.</strong> Customer will always
            comply with all international and domestic laws, ordinances,
            regulations, and statutes that are applicable to its purchase and use of
            the Offerings. Without limiting the generality of the foregoing,
            Customer will comply with all applicable export and import control laws
            and regulations in its use of the Service and Software and, in
            particular, Customer will not export or re-export the Software without
            all required United States and foreign government licenses, permits,
            and/or approvals required with respect to export regulations promulgated
            by the Bureau of Export Administration or any other agency or department
            of the federal government of the United States of America. Customer will
            defend, indemnify and hold harmless Yonomi from and against any
            violation of such laws or regulations by Customer or any of its agents,
            officers, directors, or employees. Customer acknowledges that Yonomi
            makes no representation or warranty that the Software may be exported
            without appropriate licenses or permits under applicable law, or that
            any such license or permit has been, will be or can be obtained.
            Customer is responsible for the security of the Software once delivered
            to Customer by Yonomi, and Customer will undertake security measures at
            least as protective as set forth in the Documentation. This section will
            survive expiration or termination of the Agreement.</p>
        </li>
        <li>
          <p><strong>Force Majeure</strong>. Except with respect to payment
            obligations under this Agreement, if a party is prevented or delayed in
            performance of its obligations hereunder as a result of circumstances
            beyond such party<span dir="rtl">’</span>s reasonable control,
            including, by way of example, war, riot, fires, floods, epidemics, or
            failure of public utilities or public transportation systems, such
            failure or delay will not be deemed to constitute a material breach of
            this Agreement, but such obligation will remain in full force and
            effect, and will be performed or satisfied as soon as reasonably
            practicable after the termination of the relevant circumstances causing
            such failure or delay, provided that if such party is prevented or
            delayed from performing for more than ninety (90) days, the other party
            may terminate this Agreement upon thirty (30) days<span dir="rtl">’</span> written notice.</p>
        </li>
        <li>
          <p><strong>Assignment.</strong> Customer may not assign or transfer,
            by operation of law or otherwise, any of its rights under this Agreement
            (including any licenses or subscriptions) to any third party without
            Yonomi<span dir="rtl">’</span>s prior written consent, which will not be
            unreasonably withheld if the proposed assignee assumes all Customer
            obligations hereunder. Yonomi may assign this Agreement in its
            discretion. Any attempted assignment or transfer in violation of the
            foregoing will be void.</p>
        </li>
        <li>
          <p><strong>No Third-Party Beneficiaries.</strong> The parties
            acknowledge that the covenants set forth in this Agreement are intended
            solely for the benefit of the parties, their successors and permitted
            assigns. Nothing herein, whether express or implied, will confer upon
            any person or entity, other than the parties, their successors and
            permitted assigns, any legal or equitable right whatsoever to enforce
            any provision of this Agreement.</p>
        </li>
        <li>
          <p><strong>Notices.</strong> Each party must deliver all notices or
            other communications required or permitted under this Agreement in
            writing to the other party at the address listed on the first page of
            the Agreement by courier, by certified or registered mail (postage
            prepaid and return receipt requested), or by a nationally-recognized
            express mail service. Notice will be effective upon receipt or refusal
            of delivery. If delivered by certified or registered mail, any such
            notice will be considered to have been given five (5) business days
            after it was mailed, as evidenced by the postmark.</p>
        </li>
        <li>
          <p><strong>Governing Law and Venue.</strong> This Agreement and any
            action related thereto will be governed and interpreted by and under the
            laws of the State of Colorado, without giving effect to any conflicts of
            laws principles that require the application of the law of a different
            jurisdiction. The parties hereby expressly consent to the exclusive
            personal jurisdiction and venue in the state and federal courts of
            Colorado. The United Nations Convention on Contracts for the
            International Sale of Goods does not apply to this Agreement.</p>
        </li>
        <li>
          <p><strong>Waivers; Amendment.</strong> No waiver of any terms or
            conditions of this Agreement will be valid or binding on a party unless
            such party makes the waiver in writing. The failure of one party to
            enforce any of the provisions of this Agreement, or the failure to
            require at any time the performance of the other party of any of the
            provisions of this Agreement, will in no way be construed to be a
            present or future waiver of such provisions, nor in any way affect the
            ability of a party to enforce each and every provision thereafter. This
            Agreement may be altered, amended, modified, or otherwise changed
            solely: (a) by Yonomi, by giving at least thirty (30) days<span dir="rtl">’</span> written notice to
            Customer and, to the extent there
            are material changes to the Agreement, giving Customer the right to
            terminate the Agreement prior to the end of such thirty-day period, or
            (b) by a written instrument signed by the authorized representatives of
            each party.</p>
        </li>
        <li>
          <p><strong>Severability.</strong> If any provision of this Agreement
            is ruled invalid or unenforceable for any reason by a court of competent
            jurisdiction, such provision will be construed to have been adjusted to
            the minimum extent necessary to cure such invalidity or
            unenforceability. The invalidity or unenforceability of one or more of
            the provisions contained in this Agreement will not have the effect of
            rendering any such provision invalid or unenforceable in any other case,
            circumstance or jurisdiction, or of rendering any other provisions of
            this Agreement invalid or unenforceable whatsoever.</p>
        </li>
        <li>
          <p><strong>Counterparts.</strong> This Agreement may be executed in
            any number of counterparts, each of which when so executed will be
            deemed to be an original and all of which when taken together will
            constitute one Agreement.</p>
        </li>
      </ol>
    </li>
  </ol>
  <p>IN WITNESS WHEREOF, Yonomi and Customer have caused this Agreement to
    be executed by their duly authorized officials:</p>
  <table>
    <colgroup>
      <col style="width: 45%" />
      <col style="width: 54%" />
    </colgroup>
    <tbody>
      <tr>
        <td><strong><span class="smallcaps">Yonomi, LLC.</span></strong></td>
        <td><strong><span class="smallcaps">Company:________________________
              (“Customer”)</span></strong></td>
      </tr>
      <tr>
        <td>By:</td>
        <td>By:</td>
      </tr>
      <tr>
        <td>Name:</td>
        <td>Name:</td>
      </tr>
      <tr>
        <td>Title:</td>
        <td>Title:</td>
      </tr>
      <tr>
        <td>Date:</td>
        <td>Date:</td>
      </tr>
      <tr>
        <td>
          <p>Address for Notice</p>
          <p>Yonomi, LLC.</p>
          <p>929 Pearl Street, Suite 200</p>
          <p>Boulder, CO 80302</p>
          <p>Telephone:</p>
          <p>Email:</p>
        </td>
        <td>
          <p>Address for Notice</p>
          <p>Attention:  </p>
          <p>Address:</p>
          <p>Address:</p>
          <p>Telephone:</p>
          <p>Email:</p>
        </td>
      </tr>
    </tbody>
  </table>
  <p><strong><span class="smallcaps">Data Processing
        Schedule</span></strong></p>
  <ol>
    <li>
      <p><strong><span class="smallcaps">End User Data</span></strong></p>
      <ol>
        <li>
          <p><strong>Generally</strong>. This section applies with respect to any
            Service. To the extent Customer collects Personal Data from consumers
            via Product(s), Customer will prominently display, and obtain consent
            (to the extent required by law) with respect to, its privacy policy in
            connection with the Customer Product(s) that includes a full, accurate
            and clear disclosure regarding the collection, use, disclosure, and
            other processing of Personal Data collected in connection with the
            Product(s). Customer will not provide, post or transmit any End User
            Data that: (a) infringes, misappropriates or violates any intellectual
            property rights, publicity/privacy rights, law or regulation; (b)
            contains any viruses or programming routines intended to damage,
            surreptitiously intercept or expropriate any system, data or personal or
            personally identifiable information; or (c) is deceptive, defamatory,
            obscene, pornographic or unlawful.</p>
        </li>
        <li>
          <p><strong>Coordinator</strong>. Yonomi will designate a point of
            contact as its <span dir="rtl">"</span>Privacy and Security
            Coordinator". This Privacy and Security Coordinator will: (a) maintain
            responsibility for applying adequate protections to End User Data,
            including the development, implementation, and maintenance of its
            information security program, (b) oversee application of Yonomi
            compliance with the requirements of this Schedule, and (3) serve as a
            point of contact for internal communications and communications with
            Customer pertaining to this Schedule and compliance with or any breaches
            thereof.</p>
        </li>
      </ol>
    </li>
    <li>
      <p><strong><span class="smallcaps">Yonomi Platform
            Terms</span></strong>.</p>
      <ol>
        <li>
          <p><strong>Applicability</strong>. This section applies solely with
            respect to the Yonomi Platform Service. Yonomi will act as a data
            controller with respect to End User Data that is Personal Data and is
            received by Yonomi as part of the Yonomi Platform Service (<span dir="rtl">"</span><strong>YP Personal
              Data</strong>").</p>
        </li>
        <li>
          <p><strong>Privacy Policies</strong>. Customer will comply with its
            privacy policy applicable to Customer Products. Yonomi will comply with
            its privacy policy applicable to the Yonomi Platform Service, which is
            currently available at yonomi.co/privacy and is subject to change by
            Yonomi in accordance with its terms</p>
        </li>
        <li>
          <p><strong>Requirements</strong>. Each party will implement and maintain
            reasonable technical and organization measures to protect the Customer
            End User Data disclosed to it by the other party.</p>
        </li>
        <li>
          <p><strong>Data Transfer</strong>. For all transfers of YP Personal Data
            from the EU pursuant to the Agreement, at Customer<span dir="rtl">'</span>s request, the parties will execute and
            add the
            Controller-to-Controller Standard Contractual Clauses (Set II) approved
            by the European Commission (the <span dir="rtl">"</span><strong>Controller SCCs</strong>") to this Agreement.
            To the extent there is any conflict between the body of this Schedule
            and the Controller SCCs, the Controller SCCs will control. If and when
            Yonomi certifies compliance with Privacy Shield, the Controller SCCs
            will terminate and will no longer apply to this Agreement.</p>
        </li>
        <li>
          <p><strong>Subcontractor Compliance.</strong> Each party will ensure
            that any subcontractor or agent who receives or has access to any
            Customer End User Data will be bound by contractual obligations to
            protect, maintain the confidentiality of, and lawfully process such data
            that are at least as restrictive as those contained in this Agreement
            for YP Personal Data.</p>
        </li>
        <li>
          <p><strong>Notifications.</strong> Each party will, to the extent
            legally permitted, promptly notify the other party if it receives a
            request from a data subject to exercise the data subject's rights with
            respect to Customer End User Data. Each party will notify the other
            party without undue delay after becoming aware of any security
            incident.</p>
        </li>
      </ol>
    </li>
  </ol>
`;

export const TermsOfService = () => {
  return (
    <Paper
      elevation={2}
      sx={{
        maxHeight: '60vh',
        overflow: 'auto',
        padding: 3,
        marginBottom: 2,
        marginTop: 2,
      }}
    >
      <StyledContent className="tos-content" dangerouslySetInnerHTML={{ __html: tosContent }} />
    </Paper>
  );
};
