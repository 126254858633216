import { useEffect } from 'react';
import { Box } from '@mui/material';
import { MainContainer } from '../common/Theme';
import PolicyStyling from './PolicyStyling';

const TermsOfUse = () => {
  useEffect(() => {
    document.title = 'Terms Of Use';
    const oneTrustScript = document.createElement('script');
    oneTrustScript.src = 'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
    oneTrustScript.type = 'text/javascript';
    oneTrustScript.charset = 'UTF-8';
    oneTrustScript.id = 'otprivacy-notice-script';
    oneTrustScript.setAttribute('data-testid', 'otnotice-script');
    oneTrustScript.setAttribute(
      'settings',
      'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vYWxsZWdpb24tcHJpdmFjeS5teS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9'
    );

    document.body.appendChild(oneTrustScript);

    oneTrustScript.onload = () => {
      const otContentScript = document.createElement('script');
      otContentScript.type = 'text/javascript';
      otContentScript.charset = 'UTF-8';
      otContentScript.setAttribute('data-testid', 'terms-content-script');
      otContentScript.innerHTML = `
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(['https://privacyportal-cdn.onetrust.com/8b164882-ffc8-4155-8514-dc33976fd253/privacy-notices/4a46be6f-86c6-4f88-b47f-33134447a15f.json']);
        });
      `;

      document.body.appendChild(otContentScript);
    };

    return () => {
      document.body.removeChild(oneTrustScript);
    };
  }, []);

  return (
    <MainContainer>
      <Box sx={{ padding: 2 }}>
        <PolicyStyling
          styles={{
            'section:first-of-type h2': {
              fontSize: '36px',
              fontWeight: 600,
              lineHeight: '40px',
              letterSpacing: '-1px',
              marginTop: '0px',
              marginBottom: '0.5rem',
            },
          }}
        />
        <Box id="otnotice-4a46be6f-86c6-4f88-b47f-33134447a15f" className="otnotice" sx={{ marginBottom: 2 }} />
      </Box>
    </MainContainer>
  );
};

export default TermsOfUse;
